import { ITeam } from "~/utils/api.interfaces.enums";
import { useTranslation } from "react-i18next";
import React from "react";
import { MixPanel } from "~/utils/MixPanel";

type Props = {
  activeTeam?: ITeam | null;
  ENVIRONMENT: string;
};

export function ButtonModalShareTeamPage({ activeTeam, ENVIRONMENT }: Props) {
  const { t } = useTranslation("translation");
  const shareUrl = `${
    ENVIRONMENT === "development"
      ? "https://develop.app-mingle-sport.pages.dev"
      : "https://app.mingle.sport"
  }/public/team/${activeTeam?.id}`;
  let shareData = {
    text: t("teamsPage.modalShareTeamPageText", {
      teamName: activeTeam?.name,
    }),
    url: shareUrl,
  };
  const shareTeamLink = async (e) => {
    e.preventDefault();
    if (
      navigator.canShare &&
      navigator.canShare(shareData) &&
      shareData.url &&
      window.innerWidth < 768
    ) {
      try {
        await navigator.share(shareData);
      } catch (err) {}
    } else {
      const link = document.createElement("a");
      link.href = shareUrl;
      console.log(shareUrl);
      link.click();
    }
  };

  return (
    <>
      <a
        onClick={shareTeamLink}
        // href={shareUrl}
        className={`btn btn-secondary lg:ml-auto`}
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="23"
          height="20"
          fill="none"
        >
          <path
            fill="#0B1ECF"
            fillRule="evenodd"
            d="M13.32.091a1 1 0 0 1 1.068.15l7.764 6.672a1 1 0 0 1 .008 1.51l-7.763 6.828a1 1 0 0 1-1.66-.751v-3.502c-.42.014-.95.048-1.532.123-1.441.187-3.064.61-4.201 1.483-1.18.906-2.313 2.552-3.18 4.073a30.336 30.336 0 0 0-1.345 2.663l-.016.039-.004.009v.001a1 1 0 0 1-1.918-.302L1.537 19l-.996.087v-.004l-.001-.006-.002-.02a7.348 7.348 0 0 1-.02-.33 22.04 22.04 0 0 1 .208-3.99c.35-2.396 1.227-5.459 3.426-7.689 1.902-1.93 4.397-2.536 6.318-2.686a14.53 14.53 0 0 1 2.267.004V1a1 1 0 0 1 .582-.909Zm.218 6.39-.005-.002-.036-.006a10.471 10.471 0 0 0-.77-.1 12.543 12.543 0 0 0-2.101-.017c-1.684.132-3.624.65-5.05 2.096-1.63 1.654-2.425 3.974-2.791 6.076.816-1.278 1.845-2.622 3-3.51 1.559-1.197 3.607-1.679 5.163-1.88a18.18 18.18 0 0 1 2.607-.145l.169.005.047.002.014.001h.007a1 1 0 0 1 .945.999v2.289l5.239-4.609-5.24-4.502V5.5a1 1 0 0 1-1.197.98h-.001Z"
            clipRule="evenodd"
          />
        </svg>
        Share
      </a>
    </>
  );
}
